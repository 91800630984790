.Event {
  .FixWidth {
    width: 90%;
    margin-bottom: 16px;
  }
  .BallRightUp {
    position: absolute;
    right: 32px;
    margin-top: -40px;
  }
  .BallLeftDown {
    position: absolute;
    left: 32px;
    margin-top: 6px;
  }
  h2.header {
    color: #B10099;
    text-transform: uppercase;
    .sub.header {
      text-transform: none;
    }
  }
  .row {
    .column {
      padding: 32px 0px;
      &:first-child {
        padding: 0px 24px;
      }
      & > p {
        padding: 0px 24px;
      }
      &.ExplainMalabarismo {
        .ui.header {
          padding: 0px 24px;
          color: #fff;
          font-weight: 900;
          ol {
            color: #fff;
            padding: 0px 24px;
          }
        }
      }
      &.PreguntasYRespuestas {
        .ui.header {
          padding: 0px 24px;
          color: #000;
          font-weight: 900;
          ol {
            color: #fff;
            padding: 0px 24px;
          }
        }
      }
    }
  }
  .row.QuestionInfo {
    &.QuestionInfoPaddingTop {
      margin-top: 16px;
    }
    .column {
      margin-top: 0px;
      padding-top: 0px;
      margin-bottom: 16px;
      text-align: center;
    }
  }
  .alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  .card {
    &.Answered {
      border-left: 3px solid green;
    }
    &.Pending {
      border-left: 3px solid orangered;
    }
  }
  .feed {
    .event {
      .content {
        .text.extra {
          margin-top: 0px;
          margin-bottom: 16px;
          font-size: 125%;
        }
      }
    }
  }
}
.EmptyHeader {
  color: #ed1b27 !important;
  text-align: center;
  padding: 0px 16px;
  font-size: 133% !important;
}
