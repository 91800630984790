.LoadingScreen {
  // background: #d93848;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh !important;
  width: 100vw;
  z-index: 10000;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
}
