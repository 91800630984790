.ModalActionsAnswer {
  .content {
    .ui.button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      i.icon {
        margin: 0px !important;
      }
      span {
        padding-top: 18px;
        font-size: 16px;
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
  }
}
