#JuggleLoading {
  .texts {
    text-align: center;
    margin-top: -32px;
    strong {
      font-size: 125%;
    }
    p {
      line-height: 6px;
      &:last-child {
        margin-top: 16px !important;
      }
    }
  }
  .preload {
    width: 300px;
    height: 300px;
    div {
      position: absolute;
      width: 21px;
      height: 21px;
      border-radius: 10.5px;
      background: #d93848;
      //   background: #dee5ce;
      margin-top: 150px;
      margin-left: 150px;
      animation: juggle 2.1s linear infinite;
      &:nth-child(1) {
        animation-delay: -0.7s;
      }
      &:nth-child(2) {
        animation-delay: -1.4s;
      }
    }
  }

  @keyframes juggle {
    0% {
      transform: translateX(0px) translateY(0px);
    }
    12.5% {
      transform: translateX(27.5px) translateY(-57px) scale(1.1);
      background: #ed737f;
    }
    25% {
      transform: translateX(55px) translateY(0px);
      animation-timing-function: ease-out;
    }
    37.5% {
      transform: translateX(27.5px) translateY(57px);
    }
    50% {
      transform: translateX(0px) translateY(0px);
    }
    62.5% {
      transform: translateX(-27.5px) translateY(-57px) scale(1.1);
      animation-timing-function: ease-in;
    }
    75% {
      transform: translateX(-55px) translateY(0px);
      animation-timing-function: ease-out;
    }
    87.5% {
      transform: translateX(-27.5px) translateY(57px);
    }
    100% {
      transform: translateX(0px) translateY(0px);
    }
  }
}
