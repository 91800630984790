.Passenger {
  &Wrapper {
    width: 100vw;
    height: 111vh;
    position: fixed;
    z-index: 99999;
    background: #d93848;
  }
  &Alert {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    *,
    h2.header .content {
      color: #fff !important;
      line-height: 32px;
      font-weight: 800 !important;
      text-transform: none !important;
    }
    h2.header .sub.header {
      font-weight: 100 !important;
      padding-top: 16px;
      padding-bottom: 16px;
    }
    h2.header i.icon {
      font-weight: 100 !important;
    }
  }
}
