.FancyImage {
  & > img {
    cursor: pointer;
  }
}
.FancyModal,
.FancyModal.visible.transition {
  background-color: #000 !important;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex !important;
  & > img {
    margin: 0px !important;
  }
}
